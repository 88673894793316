import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  AddCardsToCardGroupMutation,
  AddCardsToCardGroupMutationVariables,
  CardGroup,
  GetCardGroupQuery,
  GetCardGroupQueryVariables,
  SearchSpxTransactionsQuery,
  SearchSpxTransactionsQueryVariables,
  SearchableSortDirection,
  SpxTransactionsOrConditionFilterInput,
} from 'API';
import { SearchHighnoteTransactions } from 'app/pages/store/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { addMonths, format } from 'date-fns';
import { isNotNullOrUndefined } from 'util/typeGuards';
import { getCardById } from './cardService';
import { getCardGroupQuery } from './queries';
import { addCardsToCardGroup } from './mutations';

export interface GetGroupTransactionsArgs {
  paidolId: string;
  groupId: string;
  nextToken?: string;
  filterDate?: Date | null;
  searchTerms?: string | null;
  sortField?: string;
  sortOrder?: SearchableSortDirection;
}

interface GetCardGroupArgs {
  groupId: string;
  nextToken?: string;
}

export const getGroupCards = async (
  cardGroup: CardGroup,
  yearAndMonth: string = format(new Date(), 'yyyy-MM')
) => {
  const promises =
    cardGroup.paymentCards?.items.filter(isNotNullOrUndefined).map(
      (paymentCard) => async () =>
        await getCardById({
          input: {
            id: paymentCard.paymentCardId,
            paidolId: cardGroup.paidolId,
            yearAndMonth,
          },
        })
    ) || [];

  const results = (await Promise.all(promises.map((p) => p()))).filter(isNotNullOrUndefined);

  return results;
};

export const getGroupTransactions = async ({
  paidolId,
  groupId,
  nextToken,
  filterDate,
  searchTerms,
  sortField,
  sortOrder = SearchableSortDirection.desc,
}: GetGroupTransactionsArgs) => {
  const and: Array<SpxTransactionsOrConditionFilterInput> = [
    {
      or: [
        {
          cardGroupId: { eq: groupId },
        },
      ],
    },
  ];

  if (searchTerms) {
    and[0].or?.push({
      merchantName: {
        wildcard: `*${searchTerms}*`,
      },
    });
    and[0].or?.push({
      merchantCategory: {
        wildcard: `*${searchTerms}*`,
      },
    });
  }

  const variables: SearchSpxTransactionsQueryVariables = {
    input: {
      filter: {
        paidolId: { eq: paidolId },
        ...(filterDate && {
          transactionDate: {
            gt: format(new Date(filterDate), 'yyyy-MM'),
            lt: format(addMonths(new Date(filterDate), 1), 'yyyy-MM'),
          },
        }),
        and,
      },
      ...(sortField && {
        sort: {
          field: sortField,
          direction: sortOrder,
        },
      }),
      nextToken,
      limit: 20,
    },
  };

  return (
    API.graphql(graphqlOperation(SearchHighnoteTransactions, variables)) as Promise<
      GraphQLResult<SearchSpxTransactionsQuery>
    >
  ).then((result) => {
    return result.data?.searchSpxTransactions;
  });
};

export const getGroup = async ({ groupId }: GetCardGroupArgs) => {
  const variables: GetCardGroupQueryVariables = {
    id: groupId,
  };

  return (
    API.graphql(graphqlOperation(getCardGroupQuery, variables)) as Promise<GraphQLResult<GetCardGroupQuery>>
  ).then((result) => {
    return result.data?.getCardGroup as CardGroup | undefined;
  });
};

export const addCardsToGroup = async (paidolId: string, cardGroupId: string, cardIds: string[]) => {
  const variables: AddCardsToCardGroupMutationVariables = {
    input: {
      cardGroupId,
      paidolId,
      cardsIds: cardIds,
    },
  };

  return (
    API.graphql(graphqlOperation(addCardsToCardGroup, variables)) as Promise<
      GraphQLResult<AddCardsToCardGroupMutation>
    >
  ).then((result) => {
    return result.data?.addCardsToCardGroup;
  });
};
