import { Box, Button, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultColors from 'app/theme/defaultColors';

export const Content = styled(Card)<{ bgColor?: string; width?: string; padding?: string; height?: string }>(
  ({ theme, bgColor, width, padding, height }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: bgColor === 'blue' ? defaultColors.lightBlueColor : defaultColors.whiteColor,
    padding: padding || theme.spacing(4),
    borderRadius: '16px',
    boxShadow: bgColor === 'blue' ? 'none !important' : theme.shadows[3],
    width: '100%',
    maxWidth: width || 'auto',
    height: height || 'auto',
    textAlign: 'center',
    gap: theme.spacing(2),
  })
);

export const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: defaultColors.primary,
  color: defaultColors.whiteColor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '42px',
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const Title = styled(Typography)({
  color: defaultColors.primary,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '32px',
  textAlign: 'center',
});

export const Description = styled(Typography)({
  color: defaultColors.textSecondary,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center',
});

export const SVGWrapper = styled(Box)(({ theme }) => ({
  width: '154px',
  height: '154px',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
