import { combineReducers } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import agave, { AgaveState } from './agaveSlice';
import groups, { GroupsState } from 'app/pages/store/groupsSlice';
import funding, { FundingState } from './fundingSlice';
import procore, { ProcoreState } from './procoreSlice';
import issueCard, { IssueCardState } from './issueCardSlice';
import groupCards, { GroupCardsState } from 'app/pages/store/groupCardsSlice';
import spendRules, { SpendRulesState } from './spendRulesSlice';
import spendWidget, { SpendWidgetState } from './spendWidgetSlice';
import budgetWidget, { BudgetWidgetState } from './budgetWidgetSlice';
import transactions, { TransactionsState } from './transactionsSlice';
import specificCard, { SpecificCardState } from './specificCardSlice';
import paymentCards, { PaymentCardsState } from './paymentCardsSlice';
import priorityActions, { priorityActionsState } from './priorityActionsSlice';
import topMerchantsWidget, { TopMerchantsWidgetState } from './topMerchantsWidgetSlice';
import downloadTransactions, { DownloadTransactionsState } from './downloadTransactionsSlice';
import onboard from './reviewOnboardSlice';
import users from 'app/pages/store/usersSlice';
import type { ReviewOnboardState } from './reviewOnboardSlice';
import type { TeamUsersState } from 'app/pages/store/usersSlice';

enableMapSet();

export interface CardsState {
  agave: AgaveState;
  budgetWidget: BudgetWidgetState;
  downloadTransactions: DownloadTransactionsState;
  funding: FundingState;
  groups: GroupsState;
  groupCards: GroupCardsState;
  issueCard: IssueCardState;
  onboard: ReviewOnboardState;
  paymentCards: PaymentCardsState;
  priorityActions: priorityActionsState;
  procore: ProcoreState;
  specificCard: SpecificCardState;
  spendRules: SpendRulesState;
  spendWidget: SpendWidgetState;
  topMerchantsWidget: TopMerchantsWidgetState;
  transactions: TransactionsState;
  users: TeamUsersState;
}

const reducer = combineReducers({
  agave,
  budgetWidget,
  downloadTransactions,
  funding,
  groups,
  groupCards,
  issueCard,
  onboard,
  paymentCards,
  priorityActions,
  procore,
  specificCard,
  spendRules,
  spendWidget,
  topMerchantsWidget,
  transactions,
  users,
});

export default reducer;
