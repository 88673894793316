import { ReactNode, useState } from 'react';
import { Box, Grid, Paper, Typography, List, ListItem, Button, IconButton, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { useAppSelector, useAppDispatch } from 'app/store';
import {
  getCardProductApplication,
  selectReviewOnboardSlice,
  sendDocumentForManualReview,
} from 'app/pages/store/reviewOnboardSlice';
import {
  HNAccountHolderVerification,
  HNAccountHolderVerificationStatusCode,
  HNApplicationDocumentUploadSessionStatus,
  HNDocumentType,
} from 'API';
import { useTranslation } from 'react-i18next';

enum DocumentType {
  primaryAuthorizedPerson = 'primaryAuthorizedPerson',
  businessProfile = 'businessProfile',
  beneficialOwner = 'beneficialOwner',
}

interface Props {
  currentVerification?: HNAccountHolderVerification | null;
  array: HNDocumentType[];
  type: DocumentType;
}

interface FileArgs {
  id: string;
  file: File | undefined;
  documentType?: HNDocumentType;
  currentVerification?: HNAccountHolderVerification | null;
}

function AdditionalDocuments() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { application, integration } = useAppSelector(selectReviewOnboardSlice);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<{ [key: string]: FileArgs[] }>({});
  const { t } = useTranslation();

  const businessRequired =
    application?.accountHolderSnapshot?.businessProfile?.currentVerification?.reason ===
    'DOCUMENT_UPLOAD_REQUIRED';

  const individualRequired =
    application?.accountHolderSnapshot?.primaryAuthorizedPerson?.currentVerification?.reason ===
    'DOCUMENT_UPLOAD_REQUIRED';

  const beneficialOwnersRequired =
    application?.accountHolderSnapshot?.businessProfile?.ultimateBeneficialOwners?.some(
      (owner) => owner?.currentVerification?.reason === 'DOCUMENT_UPLOAD_REQUIRED'
    );

  const filterBusinessReasonsRequiredDocuments: HNDocumentType[] = (
    application?.accountHolderSnapshot?.businessProfile?.currentVerification?.requiredDocuments || []
  ).reduce((accumulator: HNDocumentType[], document) => {
    if (document?.documentUploadSession?.primaryDocumentTypes?.length) {
      accumulator.push(...document.documentUploadSession.primaryDocumentTypes);
    }
    return accumulator;
  }, []);

  const filterIndividualReasonsRequiredDocuments: HNDocumentType[] = (
    application?.accountHolderSnapshot?.primaryAuthorizedPerson?.currentVerification?.requiredDocuments || []
  ).reduce((accumulator: HNDocumentType[], document) => {
    if (document && document.documentUploadSession && document.documentUploadSession.primaryDocumentTypes) {
      accumulator.push(...document.documentUploadSession.primaryDocumentTypes);
    }
    return accumulator;
  }, []);

  const filterBeneficialOwnersReasonsRequiredDocuments = (
    application?.accountHolderSnapshot?.businessProfile?.ultimateBeneficialOwners || []
  )
    .filter((owner) => owner != null)
    .map((owner) => {
      return {
        owner,
        documents: (owner?.currentVerification?.requiredDocuments || []).reduce(
          (accumulator: HNDocumentType[], document) => {
            if (document?.documentUploadSession?.primaryDocumentTypes?.length) {
              accumulator.push(...document.documentUploadSession.primaryDocumentTypes);
            }
            return accumulator;
          },
          []
        ),
      };
    });

  const findFile = (document: HNDocumentType, currentVerification?: HNAccountHolderVerification | null) => {
    const requiredDocuments = currentVerification!.requiredDocuments!;
    const sessionId = requiredDocuments.find(
      (session) => session?.status === HNApplicationDocumentUploadSessionStatus.CREATED
    )!.documentUploadSession!.id!;
    return (files[sessionId] || []).find((file) => file.id === document);
  };

  const normalizeDocumentName = (document: HNDocumentType) => document.replaceAll('_', ' ');

  const handleChange = (file: FileArgs) => {
    const requiredDocuments = file.currentVerification!.requiredDocuments!;
    const sessionId = requiredDocuments.find(
      (session) => session?.status === HNApplicationDocumentUploadSessionStatus.CREATED
    )!.documentUploadSession!.id!;
    setFiles((oldValues) => {
      return { ...oldValues, [sessionId]: [...(oldValues[sessionId] || []), file] };
    });
  };

  const handleRemove = (id: string, currentVerification?: HNAccountHolderVerification | null) => {
    const requiredDocuments = currentVerification!.requiredDocuments!;
    const sessionId = requiredDocuments.find(
      (session) => session?.status === HNApplicationDocumentUploadSessionStatus.CREATED
    )!.documentUploadSession!.id!;
    setFiles((oldValues) => {
      return {
        ...oldValues,
        [sessionId]: [...(oldValues[sessionId] || []).filter((file) => file.id !== id)],
      };
    });
  };

  const sendFiles = () => {
    setLoading(true);
    const promises: Array<Promise<unknown>> = [];

    Object.keys(files).forEach((sessionId) => {
      promises.push(
        dispatch(
          sendDocumentForManualReview({
            documentUploadSessionId: sessionId,
            files: files[sessionId],
          })
        )
      );
    });

    if (integration) {
      Promise.all(promises)
        .then(() => new Promise((resolve) => setTimeout(resolve, 10000)))
        .then(() => dispatch(getCardProductApplication(integration)))
        .then(() => setLoading(false));
    }
  };

  const WrapList = (props: { list: ReactNode[] | undefined }) => (
    <List sx={{ ml: 2.5, pt: 0 }}>
      {props.list?.map((text, i) => (
        <ListItem
          key={`listItem-${i}`}
          sx={{
            mt: 0,
            padding: 0,
            fontSize: '12px',
            listStyleType: 'disc',
            display: 'list-item',
            fontWeight: 400,
          }}
        >
          {text}
        </ListItem>
      ))}
    </List>
  );
  const WrapperDocuments = ({ array, currentVerification, type }: Props) => {
    return (
      <Grid container gap={3} sx={{ my: 3 }}>
        {array.map((document, i) => {
          const find = findFile(document, currentVerification);
          return (
            <Grid key={`${type}-${i}`}>
              <Paper
                elevation={4}
                style={{
                  padding: 14,
                  borderRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '250px',
                  height: '300px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    mb: 0.5,
                  }}
                >
                  {t('uploadDocument')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                  }}
                >
                  {t('attachImageOfDocument')}
                </Typography>
                <Box display="flex">
                  <WrapList list={[<strong>{normalizeDocumentName(document)}</strong>]} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  {find ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        backgroundColor: theme.palette.primary.lighter,
                        borderRadius: 5,
                        px: 2.5,
                        py: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          overflow: 'hidden',
                          mr: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            overflow: 'hidden',
                            WebkitLineClamp: 1,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {find?.file?.name}
                        </Typography>
                        <span>{find?.file?.size} KB</span>
                      </Box>
                      <IconButton
                        aria-label="upload picture"
                        component="label"
                        onClick={() => handleRemove(document, currentVerification)}
                      >
                        <ClearIcon sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <Button variant="contained" component="label" sx={{ mb: 1 }}>
                      {t('chooseFile')}
                      <input
                        hidden
                        accept="image/*,.pdf"
                        multiple
                        type="file"
                        onChange={(e) =>
                          handleChange({
                            id: document,
                            file: e.target.files?.[0],
                            documentType: document,
                            currentVerification,
                          })
                        }
                      />
                    </Button>
                  )}
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            color: 'primary.main',
            fontWeight: 400,
          }}
        >
          {t('additionalInformationNeeded')}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            mb: 0.5,
            color: '#4F4F4F',
            fontWeight: 600,
          }}
        >
          {t('additionalDocumentsNeeded')}
        </Typography>
        <Typography
          sx={{
            color: '#4F4F4F',
            fontWeight: 400,
            fontSize: '1.6rem',
          }}
        >
          {t('acceptedFileTypes')}
        </Typography>
      </Box>
      {individualRequired ? (
        <Box>
          <Typography
            variant="h2"
            sx={{
              mt: 5,
              color: '#4F4F4F',
              fontWeight: 400,
            }}
          >
            {t('identityDocumentation')}
          </Typography>
          <WrapperDocuments
            currentVerification={
              application?.accountHolderSnapshot?.primaryAuthorizedPerson?.currentVerification
            }
            array={filterIndividualReasonsRequiredDocuments}
            type={DocumentType.primaryAuthorizedPerson}
          />
        </Box>
      ) : null}

      {businessRequired ? (
        <Box>
          <Typography
            variant="h2"
            sx={{
              mt: 5,
              color: '#4F4F4F',
              fontWeight: 400,
            }}
          >
            {t('businessEntityDocumentation')}
          </Typography>
          <WrapperDocuments
            currentVerification={application?.accountHolderSnapshot?.businessProfile?.currentVerification}
            array={filterBusinessReasonsRequiredDocuments}
            type={DocumentType.businessProfile}
          />
        </Box>
      ) : null}

      {beneficialOwnersRequired ? (
        <Box>
          <Typography
            variant="h2"
            sx={{
              mt: 5,
              color: '#4F4F4F',
              fontWeight: 400,
            }}
          >
            {t('uboEntityDocumentation')}
          </Typography>
          {filterBeneficialOwnersReasonsRequiredDocuments
            .filter(
              (item) =>
                item.owner?.currentVerification?.status === HNAccountHolderVerificationStatusCode.PENDING
            )
            .map((item, i) => (
              <Box key={`owner-${i}`}>
                <Typography
                  variant="h3"
                  sx={{
                    mt: 2,
                    color: '#4F4F4F',
                    fontWeight: 400,
                  }}
                >
                  {item.owner?.name?.givenName + ' ' + item.owner?.name?.familyName}
                </Typography>
                <WrapperDocuments
                  currentVerification={item.owner?.currentVerification}
                  array={item.documents}
                  type={DocumentType.beneficialOwner}
                />
              </Box>
            ))}
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', mt: 10 }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          startIcon={<SendIcon />}
          onClick={() => sendFiles()}
        >
          {t('submitFiles')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default AdditionalDocuments;
