export const trimText = (text: string): string => {
  return text.trim();
};

export const formatText = (name: string): string => {
  return name
    .replace(/[^a-zA-Z0-9\s'()\-]/g, '')
    .trim()
    .replace(/\b[a-zA-Z]/g, (char) => char.toUpperCase());
};

export const formatAddress = (name: string): string => {
  return name
    .replace(/[^a-zA-Z0-9\s'()\-\.\,]/g, '')
    .trim()
    .replace(/\b[a-zA-Z]/g, (char) => char.toUpperCase());
};

export const formatEmail = (email: string): string => {
  return email.trim().replace(/\s+/g, '').replace(/\.$/, '').toLowerCase();
};

export const formatName = (name: string): string => {
  return name
    .replace(/[^a-zA-ZÀ-ÿ\s'().\-]/g, '') // Removed 0-9
    .trim()
    .replace(/\b[a-zA-ZÀ-ÿ]/g, (char) => char.toUpperCase())
    .replace(/(?<=\bSt)\./g, '.');
};

export const sanitizePostalCode = (postalCode: string): string => {
  return postalCode.replace(/[^a-zA-Z0-9()]/g, '').trim();
};

export const sanitizeText = (name: string): string => {
  return name.replace(/[^a-zA-Z0-9\s'()\-]/g, '').trim();
};
