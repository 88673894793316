import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { RootState } from 'app/store/rootReducer';
import { listBudgetSpendByMonths } from 'graphql/queries';
import {
  BudgetSpendByMonth,
  ListBudgetSpendByMonthsQuery,
  ListBudgetSpendByMonthsQueryVariables,
  ModelSortDirection,
} from 'API';

export interface SpendWidgetState {
  spendWidget: BudgetSpendByMonth[] | null | undefined;
  averageMonthlySpend: number;
}

const initialState: SpendWidgetState = {
  spendWidget: [],
  averageMonthlySpend: 0,
};

const getListBudgetSpendByMonths = async ({
  paidolId,
  limit,
  sortDirection,
}: Omit<ListBudgetSpendByMonthsQueryVariables, 'yearAndMonth'>) => {
  return (
    API.graphql(
      graphqlOperation(listBudgetSpendByMonths, {
        paidolId,
        /* yearAndMonth: {
          beginsWith: new Date().getFullYear().toString(),
        }, */
        limit,
        sortDirection,
      })
    ) as Promise<GraphQLResult<ListBudgetSpendByMonthsQuery>>
  ).then((result) => result.data?.listBudgetSpendByMonths?.items);
};

export const getSpendWidgetByMonths = createAsyncThunk(
  'cards/overview/getSpendWidgetByMonths',
  getListBudgetSpendByMonths
);

export const getAverageMonthlySpend = createAsyncThunk(
  'cards/overview/getAverageMonthlySpend',
  (paidolId: string) =>
    getListBudgetSpendByMonths({ paidolId, limit: 6, sortDirection: ModelSortDirection.DESC })
);

const spendSlice = createSlice({
  name: 'cards/spendWidget',
  initialState,
  reducers: {
    resetSpendWidgetSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSpendWidgetByMonths.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.spendWidget = action.payload ?? initialState.spendWidget;
    });
    builder.addCase(getAverageMonthlySpend.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.averageMonthlySpend = action.payload.length
        ? action.payload?.reduce((acc, item) => acc + (item?.amount || 0), 0) / action.payload?.length
        : 0;
    });
  },
});

export const { resetSpendWidgetSlice } = spendSlice.actions;

export const selectSpendWidgetSlice = (state: RootState): SpendWidgetState =>
  state?.cards?.spendWidget ?? initialState;

export const selectAverageMonthlySpend = (state: RootState): number =>
  state?.cards?.spendWidget.averageMonthlySpend ?? 0;

export default spendSlice.reducer;
