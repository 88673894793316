import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, TextField } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { HNISO3166Alpha3Country, MerchantSpendRule } from 'API';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/store';
import { selectSpendRulesSlice } from 'app/pages/store/spendRulesSlice';
interface SpendingByCountryProps {
  control: Control<any>; // Adjust the type based on your form data structure
  selectedSpendRule?: MerchantSpendRule; // Type for MerchantSpendRule should be defined elsewhere
  isDisabled?: boolean;
}

// Functional component definition with typed props
const SpendingByCountry: React.FC<SpendingByCountryProps> = ({ control, selectedSpendRule, isDisabled }) => {
  const { t } = useTranslation();
  const { rules: highnoteRules } = useAppSelector(selectSpendRulesSlice);

  const [showCountriesSelect, setShowCountriesSelect] = useState('0');
  const handleCountriesSelectChange = (value: string) => {
    setShowCountriesSelect(value);
  };
  // Ensure selectedSpendRule is defined before accessing its properties
  const merchantCountrySpendRuleId = selectedSpendRule?.merchantCountrySpendRuleId;

  // Retrieve blocked countries safely
  const blockedCountries =
    merchantCountrySpendRuleId && highnoteRules?.has(merchantCountrySpendRuleId)
      ? highnoteRules.get(merchantCountrySpendRuleId)?.blockedCountry ?? []
      : [];

  // Retrieve allowed countries safely
  const allowedCountries =
    merchantCountrySpendRuleId && highnoteRules?.has(merchantCountrySpendRuleId)
      ? highnoteRules.get(merchantCountrySpendRuleId)?.allowedCountry ?? []
      : [];
  useEffect(() => {
    if (selectedSpendRule) {
      if (allowedCountries.length) {
        handleCountriesSelectChange('allowed');
      } else if (blockedCountries.length) {
        handleCountriesSelectChange('blocked');
      } else handleCountriesSelectChange('0'); // no restrictions
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '16px',
        backgroundColor: (theme) => theme.palette.primary.lighter,
        borderRadius: '16px',
      }}
    >
      <Typography variant="h4" color="primary.main" mb={3.5}>
        {t('spendingByCountry')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '24px',
          backgroundColor: '#fff',
          borderRadius: '16px',
        }}
      >
        <Controller
          name="spendingByCountryType"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                disabled={!!selectedSpendRule || isDisabled}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  handleCountriesSelectChange(event.target.value);
                }}
                variant="standard"
                disableUnderline
                value={showCountriesSelect}
              >
                <MenuItem value={'0'}>
                  <em>{t('noRestrictions')}</em>
                </MenuItem>
                <MenuItem value={'blocked'}>{t('allCountriesExcept')}</MenuItem>
                <MenuItem value={'allowed'}>{t('noCountriesExcept')}</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        {/* Spending by country options */}
        {showCountriesSelect !== '0' && (
          <Box mt={1}>
            <Controller
              name="spendingByCountry"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  sx={{ backgroundColor: '#fff' }}
                  onChange={(event, value) => field.onChange(value)}
                  multiple
                  options={Object.values(HNISO3166Alpha3Country)}
                  getOptionLabel={(option) => option || ''}
                  disabled={!!selectedSpendRule || isDisabled}
                  value={
                    allowedCountries.length
                      ? allowedCountries
                      : blockedCountries.length
                      ? blockedCountries
                      : field.value
                  }
                  filterSelectedOptions
                  id="spendingByCountry-autocomplete-input"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SpendingByCountry;
