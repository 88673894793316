import { Box, Button, Grid, styled } from '@mui/material';

export const CardDetails = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginRight: theme.spacing(2),
  },
}));

export const CardUnactivatedMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  borderBottomLeftRadius: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  marginBottom: theme.spacing(2),
  width: '100%',
}));

export const CardImageContainer = styled(Grid)<{ isCardUnactivated?: boolean }>(
  ({ theme, isCardUnactivated }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: isCardUnactivated ? 0 : theme.spacing(2),
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      justifyContent: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  })
);

export const CardControlsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(2),
  minWidth: '140px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(4),
  },
}));

export const SecurityButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  padding: '0.5px',
}));
