import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import BudgetIcon from '@mui/icons-material/AccountBalance'; // Replace with your Budget icon import
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { MerchantSpendRule } from 'API';
import { useAppSelector } from 'app/store';
import { selectSpendRulesSlice } from 'app/pages/store/spendRulesSlice';

interface MonthlySpendLimitProps {
  selectedSpendRule?: MerchantSpendRule;
}

const MonthlyLimitSpendRule: React.FC<MonthlySpendLimitProps> = ({ selectedSpendRule }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const { rules: highnoteRules } = useAppSelector(selectSpendRulesSlice);
  const monthlyLimitVelocityRuleId = selectedSpendRule?.monthlyLimitSpendRuleId;
  let monthlyLimitSpendRuleId: string | undefined;
  if (monthlyLimitVelocityRuleId) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    monthlyLimitSpendRuleId = highnoteRules.get(monthlyLimitVelocityRuleId)?.cumulativeRule?.id;
  }

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: '16px',
        background: 'var(--Blues-Highlight-Blue, #F2F6FF)',
      }}
    >
      <Controller
        name="monthlySpendLimit"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            error={!!errors.monthlySpendLimit}
            // helperText={errors?.monthlySpendLimit?.message}
            label={t('monthlySpendLimit')}
            placeholder="No limit"
            id="monthlySpendLimit"
            variant="outlined"
            defaultValue={
              (monthlyLimitSpendRuleId
                ? (highnoteRules.get(monthlyLimitSpendRuleId)?.maximumAmount?.value ?? 0) / 100
                : '') ?? ''
            }
            disabled={
              watch('spendRuleSelector') === 'existing' || !!selectedSpendRule?.monthlyLimitSpendRuleId
            }
            fullWidth
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">
                  <BudgetIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default MonthlyLimitSpendRule;
