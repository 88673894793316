import { Box, FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import { selectUserCompanies, setSelectedPaidolId } from 'app/store/userCompaniesSlice';
import { resetTopMerchantsWidgetSlice } from 'app/pages/store/topMerchantsWidgetSlice';
import { resetDownloadTransactions } from 'app/pages/store/downloadTransactionsSlice';
import { resetPriorityActionsSlice } from 'app/pages/store/priorityActionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { resetPaymentCardsSlice } from 'app/pages/store/paymentCardsSlice';
import { resetTransactionsSlice } from 'app/pages/store/transactionsSlice';
import { resetBudgetWidgetSlice } from 'app/pages/store/budgetWidgetSlice';
import { resetSpecificCardSlice } from 'app/pages/store/specificCardSlice';
import { resetSpendWidgetSlice } from 'app/pages/store/spendWidgetSlice';
import { resetUserProfileSlice } from 'app/store/userProfileSlice';
import { resetSpendRulesSlice } from 'app/pages/store/spendRulesSlice';
import { resetSearchAndFilter } from 'app/store/searchAndFilterSlice';
import { resetNavigationSlice } from 'app/store/navigationSlice';
import { resetIssueCardSlice } from 'app/pages/store/issueCardSlice';
import { resetFundingSlice } from 'app/pages/store/fundingSlice';
import { resetProcoreSlice } from 'app/pages/store/procoreSlice';
import { resetOnboardSlice } from 'app/store/onboardSlice';
import { resetWalletSlice } from 'app/store/walletSlice';
import { resetGroupsSlice } from 'app/pages/store/groupsSlice';
import { resetDialogSlice } from 'app/store/dialogSlice';
import { resetAgaveSlice } from 'app/pages/store/agaveSlice';
import { clearUsers } from 'app/pages/store/usersSlice';

function CompanySwitcher({ sx = {} }) {
  const { isUserOnboarded, paidolUsers, selectedPaidolId } = useSelector(selectUserCompanies);
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    await dispatch(setSelectedPaidolId(event.target.value));
    [
      clearUsers(),
      resetAgaveSlice(),
      resetDialogSlice(),
      resetWalletSlice(),
      resetGroupsSlice(),
      resetOnboardSlice(),
      resetFundingSlice(),
      resetProcoreSlice(),
      resetIssueCardSlice(),
      resetNavigationSlice(),
      resetSpendRulesSlice(),
      resetSearchAndFilter(),
      resetUserProfileSlice(),
      resetSpendWidgetSlice(),
      resetBudgetWidgetSlice(),
      resetPaymentCardsSlice(),
      resetSpecificCardSlice(),
      resetTransactionsSlice(),
      resetReviewOnboardSlice(),
      resetDownloadTransactions(),
      resetPriorityActionsSlice(),
      resetTopMerchantsWidgetSlice(),
    ].forEach((action) => dispatch(action));
  };

  if (paidolUsers.length === 1) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={selectedPaidolId === ''}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Please select a company"
        arrow
      >
        <div>
          <FormControl fullWidth size="small">
            {isUserOnboarded && (
              <Select
                sx={{ backgroundColor: 'white' }}
                displayEmpty
                variant="outlined"
                labelId="demo-simple-select-label"
                // label="Company"
                id="demo-simple-select"
                value={selectedPaidolId}
                onChange={handleChange}
                defaultValue=""
              >
                {!selectedPaidolId && (
                  <MenuItem key="empty value" value="">
                    <em>Select Company</em>
                  </MenuItem>
                )}
                {paidolUsers.map((paidolUser) => (
                  <MenuItem key={paidolUser.paidol.id} value={paidolUser.paidol.id}>
                    {paidolUser.paidol.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
      </Tooltip>
    </Box>
  );
}

export default CompanySwitcher;
