import { Avatar, Box, Typography } from '@mui/material';
import { GetCardsCardholderPayload } from 'API';
import { stringAvatar } from 'app/pages/team/groups/utils';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

// Type for the mapping of user IDs to their profile picture URLs
type UserProfilePictureMap = Record<string, string>;

const getProfilePictureMap = async (
  cardholders: (GetCardsCardholderPayload | null | undefined)[]
): Promise<UserProfilePictureMap> => {
  const userProfilePictureMap: UserProfilePictureMap = {};

  for (let index = 0; index < cardholders.length; index++) {
    const cardholder = cardholders[index];
    if (cardholder) {
      const userPicturePath = cardholder.picture;
      const userId = cardholder.id;

      if (userPicturePath && userId && !userProfilePictureMap[userId]) {
        const url = await Storage.get(userPicturePath);
        userProfilePictureMap[userId] = url;
      }
    }
  }

  return userProfilePictureMap;
};

function useProfilePictureMap(cardholders: (GetCardsCardholderPayload | null | undefined)[]): {
  userProfilePictureMap: UserProfilePictureMap;
  CardholderComponent: (cardholder: GetCardsCardholderPayload | null | undefined) => JSX.Element | null;
} {
  const [userProfilePictureMap, setUserProfilePictureMap] = useState<UserProfilePictureMap>({});

  useEffect(() => {
    async function mapProfilePictures() {
      setUserProfilePictureMap(await getProfilePictureMap(cardholders));
    }

    mapProfilePictures();
  }, [cardholders]);

  const renderCardholderPicture = (cardholder: GetCardsCardholderPayload): JSX.Element => {
    const { id, name, email } = cardholder;

    const stringAvatarStyled = stringAvatar(name || email);
    Object.assign(stringAvatarStyled.sx, {
      width: 32,
      height: 32,
      fontSize: '1em',
    });

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          sx={{ maxWidth: 32, maxHeight: 32, fontSize: '1em' }}
          key={id}
          title={name || email}
          {...(userProfilePictureMap[id]
            ? { src: userProfilePictureMap[id] }
            : {
                ...stringAvatarStyled,
              })}
        />
        <Typography variant="h4" fontWeight="light" ml={1.5}>
          {name || email}
        </Typography>
      </Box>
    );
  };

  const renderCardholder = (cardholder: GetCardsCardholderPayload | null | undefined): JSX.Element | null =>
    cardholder ? renderCardholderPicture(cardholder) : null;

  return {
    userProfilePictureMap,
    CardholderComponent: renderCardholder,
  };
}

export default useProfilePictureMap;
