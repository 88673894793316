import logger from 'util/logging';
import { withLoading } from 'app/store/utils';
import { captureException } from '@sentry/react';
import serializeError, { ErrorLike } from 'util/serializeError';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { isNotNullOrUndefined, isPaidolUser } from 'util/typeGuards';
import { deletePaidolUser, inviteUser, updatePaidolUser } from 'graphql/mutations';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  GetGroupPaidolUsers,
  paidolUserByPaidolId,
  paidolUserByPaidolIdNoLimit,
} from 'app/pages/store/usersSliceQueries';
import type { GetGroupPaidolUsersQuery, PaidolUserByPaidolIdQuery } from 'app/pages/store/usersSliceQueries';
import type { GraphQLResult } from '@aws-amplify/api-graphql';
import type { RootState } from 'app/store/rootReducer';
import {
  DeletePaidolUserMutation,
  InviteUserMutation,
  PaidolUser,
  Role,
  UpdatePaidolUserMutation,
} from 'API';

const log = logger();

interface GetUsersArgs {
  rowsPerPage: number;
  nextToken: string | null;
  selectedCompany: string;
}

export const getUsers = createAsyncThunk<Array<PaidolUser> | void, GetUsersArgs, { rejectValue: ErrorLike }>(
  'team/users/getUsers',
  async ({ rowsPerPage, nextToken = null, selectedCompany }, { rejectWithValue, dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(paidolUserByPaidolId, {
          paidol_id: selectedCompany,
          limit: rowsPerPage,
          nextToken,
        })
      ) as Promise<GraphQLResult<PaidolUserByPaidolIdQuery>>
    )
      .then((res) => {
        const paidolUsers =
          res?.data?.paidolUserByPaidolId?.items.filter(isNotNullOrUndefined).filter(isPaidolUser) || [];

        dispatch(setNextToken(res?.data?.paidolUserByPaidolId?.nextToken || null));
        dispatch(getUsersPictures(paidolUsers));

        return paidolUsers;
      })
      .catch((error) => {
        rejectWithValue(serializeError(error));
      });
  }
);

interface getAdminsArgs {
  selectedCompany: string;
  roleType: 'admin' | 'group' | 'both';
}

export const getAdmins = createAsyncThunk<
  Array<PaidolUser> | void,
  getAdminsArgs,
  { rejectValue: ErrorLike }
>('team/users/getAdmins', async ({ selectedCompany, roleType }, { rejectWithValue, dispatch }) => {
  try {
    const queryByRole = async (role: Role): Promise<PaidolUser[]> => {
      const response = (await API.graphql(
        graphqlOperation(paidolUserByPaidolIdNoLimit, {
          paidol_id: selectedCompany,
          roles: { eq: role },
        })
      )) as GraphQLResult<PaidolUserByPaidolIdQuery>;

      return (
        response?.data?.paidolUserByPaidolId?.items.filter(isNotNullOrUndefined).filter(isPaidolUser) || []
      );
    };

    let paidolUsers: PaidolUser[] = [];

    if (roleType === 'admin') {
      paidolUsers = await queryByRole(Role.ADMINISTRATOR);
    } else if (roleType === 'group') {
      paidolUsers = await queryByRole(Role.GROUP_ADMINISTRATOR);
    } else if (roleType === 'both') {
      const [admins, groupAdmins] = await Promise.all([
        queryByRole(Role.ADMINISTRATOR),
        queryByRole(Role.GROUP_ADMINISTRATOR),
      ]);

      paidolUsers = Array.from(new Set([...admins, ...groupAdmins].map((user) => user.id)))
        .map((id) => [...admins, ...groupAdmins].find((user) => user?.id === id))
        .filter(isNotNullOrUndefined);
    }
    dispatch(setGroupAdmins(paidolUsers));
    dispatch(getUsersPictures(paidolUsers));

    return paidolUsers;
  } catch (error) {
    console.error('GraphQL Error:', error);
    return rejectWithValue(serializeError(error as Error));
  }
});

export interface GetGroupsUsersArgs {
  paidolId: string;
  nextToken?: string;
  limit?: number;
  userId: string;
  userRole?: Role;
}

export const getGroupUsers = createAsyncThunk<
  Array<PaidolUser> | void,
  GetGroupsUsersArgs,
  { rejectValue: ErrorLike }
>(
  'cards/groups/getGroupUsers',
  async (
    { paidolId, userId, userRole, nextToken, limit }: GetGroupsUsersArgs,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const results = await (API.graphql(
        graphqlOperation(GetGroupPaidolUsers, { paidolId, nextToken, limit })
      ) as Promise<GraphQLResult<GetGroupPaidolUsersQuery>>);

      const groups = results.data?.listCardGroupsByPaidolId?.items || [];

      const filteredGroups = groups.filter(
        (group) =>
          group.paymentCards?.items?.some((card) =>
            card?.paidolUsers?.items?.some((userItem) => userItem?.paidolUser?.user?.id === userId)
          ) || group.groupAdmins?.items?.some((admin) => admin?.paidolUser?.user?.id === userId)
      );

      const paidolUsers = filteredGroups
        ?.flatMap((group) => {
          const admins = group.groupAdmins?.items?.map((adminItem) => {
            const paidolUser = adminItem?.paidolUser;
            const user = paidolUser?.user;
            if (paidolUser?.id && paidolUser?.email && user?.id) {
              return {
                user_id: paidolUser.user_id,
                id: paidolUser.id,
                email: paidolUser.email,
                roles: paidolUser.roles || null,
                user: {
                  id: user.id,
                  first_name: user.first_name || null,
                  last_name: user.last_name || null,
                  picture: user.picture || null,
                  email: user.email || null,
                },
              };
            }
            return null;
          });

          const users = group.paymentCards?.items?.flatMap((card) =>
            card?.paidolUsers?.items?.map((userItem) => {
              const paidolUser = userItem?.paidolUser;
              const user = paidolUser?.user;
              if (paidolUser?.id && paidolUser?.email && user?.id) {
                return {
                  user_id: paidolUser.user_id,
                  id: paidolUser.id,
                  email: paidolUser.email,
                  roles: paidolUser.roles || null,
                  user: {
                    id: user.id,
                    first_name: user.first_name || null,
                    last_name: user.last_name || null,
                    picture: user.picture || null,
                    email: user.email || null,
                  },
                };
              }
              return null;
            })
          );

          return userRole === 'GROUP_ADMINISTRATOR'
            ? [...(admins || []), ...(users || [])]
            : [...(admins || []), ...(users?.filter((user) => user?.roles !== 'GROUP_ADMINISTRATOR') || [])];
        })
        .filter(Boolean) as PaidolUser[];

      const usersResult = await dispatch(
        getUsers({
          selectedCompany: paidolId,
          rowsPerPage: 1000,
          nextToken: null,
        })
      ).unwrap();

      const allUsers = Array.isArray(usersResult) ? usersResult : [];

      const userInPaidolUsers = paidolUsers.some((user) => user.user?.id === userId);

      if (!userInPaidolUsers) {
        const fetchedUser = allUsers.find((user) => user.user?.id === userId);
        if (fetchedUser) {
          paidolUsers.unshift(fetchedUser);
        }
      }

      dispatch(getUsersPictures(paidolUsers));

      return paidolUsers;
    } catch (error) {
      captureException(error);
      return rejectWithValue({
        message: 'Failed to fetch group users',
        name: '',
      });
    }
  }
);

export const getUsersPictures = createAsyncThunk(
  'team/users/getUsersPictures',
  async (paidolUsers: Array<PaidolUser>) => {
    return Promise.all(
      paidolUsers.map((paidolUser) => {
        if (paidolUser.user && paidolUser.user.picture) {
          return Storage.get(paidolUser.user.picture, {
            level: 'public',
            expires: 86400, // 1 day
          }).then((url) => {
            return [paidolUser.user_id, url];
          });
        }
        return [paidolUser.user_id, undefined];
      })
    );
  }
);

export const removeUser = createAsyncThunk('team/users/removeUser', async (userId: string) => {
  const input = {
    id: userId,
  };
  return (
    API.graphql(graphqlOperation(deletePaidolUser, { input })) as Promise<
      GraphQLResult<DeletePaidolUserMutation>
    >
  )
    .then(() => {
      return userId;
    })
    .catch((error) => {
      log.error(error);
    });
});

interface InviteUserArgs {
  selectedCompany: string;
  email: string;
  roles: string;
}

export const addUser = createAsyncThunk<
  InviteUserMutation['inviteUser'],
  InviteUserArgs,
  { rejectValue: ErrorLike }
>('team/users/addUser', async (params: InviteUserArgs, { rejectWithValue }) => {
  const companyUser = {
    paidol_id: params.selectedCompany,
    email: params.email.toLowerCase(), // normalize email address
    roles: params.roles,
  };

  let hostname = window.location.origin;
  if (!window.location.origin) {
    hostname =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
  }

  return (
    API.graphql(graphqlOperation(inviteUser, { input: companyUser, hostname })) as Promise<
      GraphQLResult<InviteUserMutation>
    >
  )
    .then((res) => {
      return res?.data?.inviteUser || rejectWithValue({ message: 'An unknown error occurred' } as ErrorLike);
    })
    .catch((error) => {
      return rejectWithValue(serializeError(error.errors[0]));
    });
});

interface UpdateUserArgs {
  id: string;
  roles: Role | null | undefined;
}

export const updateUser = createAsyncThunk(
  'team/users/updateUser',
  async (params: UpdateUserArgs, { rejectWithValue }) => {
    const input = {
      id: params.id,
      roles: params.roles,
    };

    return (
      API.graphql(graphqlOperation(updatePaidolUser, { input })) as Promise<
        GraphQLResult<UpdatePaidolUserMutation>
      >
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return rejectWithValue(serializeError(error.errors[0]));
      });
  }
);

const usersAdapter = createEntityAdapter<PaidolUser>({
  sortComparer: (a, b) => a.roles?.localeCompare(b.roles ?? '') ?? 0,
});

function removeDuplicates(users: PaidolUser[]): PaidolUser[] {
  const uniqueUsers = new Map<string, PaidolUser>();

  users.forEach((user) => {
    if (user.user_id && !uniqueUsers.has(user.user_id)) {
      uniqueUsers.set(user.user_id, user);
    }
  });

  return Array.from(uniqueUsers.values());
}

const initialState = usersAdapter.getInitialState({
  loading: false,
  searchText: '',
  nextToken: null,
  pictureUrls: {} as Record<string, string>,
  panelOpen: false,
  users: [] as Array<PaidolUser>,
  groupAdmins: [] as PaidolUser[],
  groupUsers: [] as Array<PaidolUser>,
});

export type TeamUsersState = typeof initialState;

const usersSlice = createSlice({
  name: 'team/users',
  initialState,
  reducers: {
    setCompaniesSearchText: (state, action) => {
      state.searchText = action.payload;
      state.nextToken = null;
    },
    setNextToken: (state, action) => {
      state.nextToken = action.payload;
    },
    resetNextToken: (state) => {
      state.nextToken = null;
    },
    clearUsers: () => {
      return initialState;
    },
    setPanelOpen: (state, action) => {
      state.panelOpen = action.payload;
    },

    setGroupAdmins: (state, action) => {
      state.groupAdmins = action.payload;
    },
  },
  extraReducers: (builder) => {
    withLoading(getUsers, builder, {
      afterFulfilled: (state, action) => {
        if (action.payload) {
          usersAdapter.addMany(state, action.payload);
        }
      },
    });

    builder.addCase(getGroupUsers.fulfilled, (state, action) => {
      const combinedUsers = [...state.groupAdmins, ...(action.payload || [])];
      state.groupUsers = removeDuplicates(combinedUsers);
    });

    builder.addCase(getUsersPictures.fulfilled, (state, action) => {
      if (action.payload) {
        action.payload.forEach(([user_id, url]) => {
          if (isNotNullOrUndefined(user_id) && isNotNullOrUndefined(url)) {
            state.pictureUrls[user_id] = url;
          }
        });
      }
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      if (action.payload) {
        usersAdapter.addOne(state, action.payload as PaidolUser);
      }
    });

    builder.addCase(removeUser.fulfilled, (state, action) => {
      if (action.payload) {
        usersAdapter.removeOne(state, action.payload);
      }
    });
  },
});

export const {
  setGroupAdmins,
  setCompaniesSearchText,
  setNextToken,
  resetNextToken,
  clearUsers,
  setPanelOpen,
} = usersSlice.actions;

export const selectUsersSlice = (state: RootState): TeamUsersState => state.cards?.users ?? initialState;

export const { selectAll: selectUsers, selectById: selectProductById } =
  usersAdapter.getSelectors(selectUsersSlice);

export default usersSlice.reducer;
