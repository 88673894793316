import DebitCardVirtual from 'app/shared-components/cards/assets/card-debit-white.svg';
import DebitCardPhysical from 'app/shared-components/cards/assets/card-debit-blue.svg';
import CreditCardPhysical from 'app/shared-components/cards/assets/card-credit-black.svg';
import DebitCardVirtualSmall from 'app/shared-components/cards/assets/card-debit-white-small.png';
import DebitCardPhysicalSmall from 'app/shared-components/cards/assets/card-debit-blue-small.png';
import CreditCardPhysicalSmall from 'app/shared-components/cards/assets/card-credit-black-small.png';
import { HNCardFormFactor, HighnoteProductType } from 'API';

export type DefaultCardType = {
  type: HighnoteProductType;
  formFactor: HNCardFormFactor;
  textColor: 'light' | 'dark';
  cardImage: string;
  smallCardImage: string;
};

export const DefaultCardTypes: DefaultCardType[] = [
  {
    type: HighnoteProductType.CREDIT,
    formFactor: HNCardFormFactor.PHYSICAL,
    textColor: 'light',
    cardImage: CreditCardPhysical,
    smallCardImage: CreditCardPhysicalSmall,
  },
  {
    type: HighnoteProductType.DEBIT,
    formFactor: HNCardFormFactor.PHYSICAL,
    textColor: 'light',
    cardImage: DebitCardPhysical,
    smallCardImage: DebitCardPhysicalSmall,
  },
  {
    type: HighnoteProductType.DEBIT,
    formFactor: HNCardFormFactor.VIRTUAL,
    textColor: 'dark',
    cardImage: DebitCardVirtual,
    smallCardImage: DebitCardVirtualSmall,
  },
];

export const checkCardType = (type?: HighnoteProductType, formFactor?: HNCardFormFactor): DefaultCardType => {
  // Attempt to find the card type
  const foundCardType = DefaultCardTypes.find(
    (cardType) => cardType.type === type && cardType.formFactor === formFactor
  );

  // Return the found card type or fall back to the first entry
  return foundCardType || DefaultCardTypes[0];
};
