export const getCardGroupQuery = /* GraphQL */ `
  query getCardGroup($id: ID!) {
    getCardGroup(id: $id) {
      id
      paidolId
      name
      monthlyBudget {
        currency
        value
      }
      monthlyBudgetSpend {
        items {
          amount
          yearAndMonth
        }
      }
      groupAdmins {
        items {
          id
          paidolUser {
            email
            user {
              id
              username
              email
              picture
              first_name
              last_name
            }
          }
        }
      }
      paymentCards {
        nextToken
        items {
          paymentCardId
          paidolUsers(limit: 100) {
            items {
              id
              paidolUser {
                email
                user {
                  id
                  username
                  email
                  picture
                  first_name
                  last_name
                }
              }
            }
          }
        }
      }
    }
  }
`;
