import { ColorPalette } from 'API';

export type DefaultColorPalette = Required<
  Omit<
    {
      [K in keyof ColorPalette]: NonNullable<ColorPalette[K]>;
    },
    '__typename'
  >
>;

const defaultColors: DefaultColorPalette = {
  primary: '#324C80',
  primaryDark: '#1B3059',
  primaryDarker: '#141F33',
  primaryLight: '#B2CCFF',
  primaryLighter: '#EEF2FF',
  secondary: '#00BFFF',
  secondaryDark: '#4A7AAC',
  textPrimary: '#212121',
  textSecondary: '#424242',
  whiteColor: '#FFFFFF',
  successColor: '#079827',
  warningColor: '#F2994A',
  errorColor: '#EB5757',
  errorLightColor: '#FFE4E4',
  info: '#3377FF',
  lightestGrayColor: '#F4F4F4',
  lightGrayColor: '#FAFAFA',
  lightBlueColor: '#F2F6FF',
};

// Keep this comment for testing purposes.
// export const otherColors: DefaultColorPalette = {
//   primary: '#803272',
//   primaryDark: '#56194B',
//   primaryDarker: '#32152C',
//   primaryLight: '#E3B5D4',
//   primaryLighter: '#F8F2F8',
//   secondary: '#EA42CB',
//   secondaryDark: '#AA4998',
//   textPrimary: '#212121',
//   textSecondary: '#424242',
//   whiteColor: '#FFFFF',
//   successColor: '#079827',
//   warningColor: '#F2994A',
//   errorColor: '#EB5757',
//   errorLightColor: '#F0D0D0',
//   info: '#B54DA1',
//   lightestGrayColor: '#F4F4F4',
// };

export default defaultColors;
