import { captureException } from '@sentry/react';
import { GetCardsPayload, HNCardFormFactor } from 'API';
import { FormType, NewCardFormValues } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { resetGroupCards, triggerGroupUpdate } from 'app/pages/store/groupCardsSlice';
import { attachSpendRules, getGroups } from 'app/pages/store/groupsSlice';
import { issuePaymentCardForFinancialAccount } from 'app/pages/store/issueCardSlice';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { formatISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleHNErrors } from 'util/errorHandling';
import { useUpdateFirstRunState } from './useUpdateFirstRunState';

export interface NewCardSheetProps {
  selectedPaymentCard?: GetCardsPayload;
  formType: FormType;
  loadPaymentCards?: () => Promise<void>;
  onUpdateCard?: () => void;
  handleClose?: () => void;
}

export const useIssueOrUpdateCard = (props: NewCardSheetProps) => {
  const { formType, selectedPaymentCard, onUpdateCard, loadPaymentCards, handleClose } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { selectedPaidolId: selectedCompany } = useAppSelector(selectUserCompanies);
  const [loading, setLoading] = useState(false);

  const { triggerUpdate } = useUpdateFirstRunState({
    operationName: 'completeFirstRunChecklist',
    firstRunValue: 'issueCard',
  });

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getGroups({ paidolId: selectedCompany }));
    }
  }, [dispatch, selectedCompany]);

  const onSaveCard = useCallback(
    async (form: NewCardFormValues) => {
      if (!integration || !selectedCompany || !integration.financialAccountId) {
        enqueueSnackbar(t('unknownError'), { variant: 'error' });
        captureException(
          new Error('Integration or selected company is not available when trying to save card'),
          { extra: { integration, selectedCompany } }
        );
        return;
      }

      setLoading(true);
      triggerUpdate();
      let hostname = window.location.origin;
      if (!window.location.origin) {
        const port = window.location.port ? `:${window.location.port}` : '';
        hostname = `${window.location.protocol}//${window.location.hostname}${port}`;
      }

      const isPhysical = form.cardType === HNCardFormFactor.PHYSICAL;
      const isNotUpdate = form.formType !== FormType.UpdateCard;

      dispatch(
        issuePaymentCardForFinancialAccount({
          input: {
            formType,
            selectedPaymentCardId: selectedPaymentCard?.paymentCardId,
            financialAccountId: integration.financialAccountId,
            ...(integration.cardProfileId && { cardProfileId: integration.cardProfileId }),
            cardExpirationDate: formatISO(form.cardExpirationDate),
            cardType: form.cardType as HNCardFormFactor,
            email: form.email,
            ...(form.user?.id &&
              form.user?.name && {
                procoreEmployee: {
                  id: form.user.id,
                  name: form.user.name,
                  email_address: form.user.email_address,
                },
              }),
            paidolId: selectedCompany,
            hostname,
            cardName: form.cardName,
            cardGroupId: form.cardGroupId,
            maximumTransactionAmount: form.maximumTransactionAmount,
            monthlySpendLimit: form.monthlySpendLimit,
            merchantSpendRuleId: form.merchantSpendRuleId,
            requireCardCode: form.requireCardCode,
            requireAddress: form.requireAddress,
            ...(isPhysical &&
              isNotUpdate && {
                addressFirstName: form.addressFirstName
                  ? form.addressFirstName.trim()
                  : form.addressFirstName,
                addressLastName: form.addressLastName ? form.addressLastName.trim() : form.addressLastName,
                addressStreet1: form.addressStreet1 ? form.addressStreet1.trim() : form.addressStreet1,
                addressStreet2: form.addressStreet2 ? form.addressStreet2.trim() : form.addressStreet2,
                addressZip: form.addressZip ? form.addressZip.trim() : form.addressZip,
                addressCity: form.addressCity ? form.addressCity.trim() : form.addressCity,
                addressState: form.addressState,
                shippingMethod: form.shippingMethod !== 'NO_SHIPPING' ? form.shippingMethod : undefined,
                requireSignature: form.requireSignature,
              }),
          },
        })
      )
        .unwrap()
        .then(async (response) => {
          handleClose?.();
          // Refresh cards list. Timeout is necessary because HN is not retrieving updated data immediately
          loadPaymentCards && setTimeout(async () => await loadPaymentCards(), 1000);

          if (form?.cardGroupId && form?.cardGroupId !== 'NO_GROUP') {
            dispatch(resetGroupCards());
            await dispatch(attachSpendRules({ cardGroupId: form.cardGroupId }));
          } else {
            console.warn('No card group selected, skipping spend rules attachment');
          }

          enqueueSnackbar(
            formType === 'ISSUE_CARD'
              ? 'Card has been successfully issued.'
              : formType === 'ORDER_PHYSICAL'
              ? 'Physical card has been successfully ordered.'
              : 'Changes to card have been saved.',
            {
              variant: 'success',
            }
          );
        })
        .catch((error) => {
          captureException(error);
          if (handleHNErrors(error, enqueueSnackbar, 'An error has occurred:')) return;

          enqueueSnackbar(t('unknownError'), { variant: 'error' });
          throw error;
        })
        .finally(() => {
          setLoading(false);
          onUpdateCard?.();
          dispatch(triggerGroupUpdate());
        });
    },
    [
      integration,
      selectedCompany,
      dispatch,
      formType,
      selectedPaymentCard?.paymentCardId,
      enqueueSnackbar,
      handleClose,
      loadPaymentCards,
      onUpdateCard,
      triggerUpdate,
      t,
    ]
  );

  return { onSaveCard, loading, formType };
};
