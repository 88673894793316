import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  CardGroup,
  GetCardByHighnoteIdInput,
  GetCardsPayload,
  GetCardByHighnoteIdQuery,
  GetCardsInput,
  GetCardsQuery,
  GetCardsPayloadResult,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import { getCardByHighnoteId } from 'graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getCards } from './queries';

// Define the initial state
export type GroupCardsState = {
  groupCards: GetCardsPayload[];
  groupCardsLoading: boolean;
  assinableCards: GetCardsPayload[];
  assinableCardsLoading: boolean;
  error: string | null;
  updateGroup: number;
};

const initialState: GroupCardsState = {
  groupCards: [],
  groupCardsLoading: false,
  assinableCards: [],
  assinableCardsLoading: false,
  error: null,
  updateGroup: 0,
};

export interface FetchGroupCardsArgs {
  searchTerm: string;
  paidolId: string;
}

// Async thunk for fetching group cards
export const fetchGroupCards = createAsyncThunk(
  'groupCards/fetchGroupCards',
  async ({ paidolId, cardGroup }: { paidolId: string; cardGroup: CardGroup }) => {
    const input: GetCardsInput = {
      filter: {
        paidolId: {
          eq: paidolId,
        },
        cardGroupId: {
          eq: cardGroup.id,
        },
      },
    };
    return (API.graphql(graphqlOperation(getCards, { input })) as Promise<GraphQLResult<GetCardsQuery>>).then(
      (result) => {
        return result.data?.getCards as GetCardsPayloadResult;
      }
    );
  }
);

export const fetchAssinableCards = createAsyncThunk(
  'cards/paymentCards/fetchAssinableCards',
  async ({ paidolId, searchTerm }: FetchGroupCardsArgs) => {
    const input: GetCardsInput = {
      filter: {
        paidolId: {
          eq: paidolId,
        },
        cardGroupId: {
          exists: false,
        },
      },
    };
    if (searchTerm.match(/^[0-9]+$/)) {
      input.filter = {
        ...input.filter,
        last4: {
          eq: `${searchTerm}`,
        },
      };
    } else if (searchTerm) {
      input.filter = {
        ...input.filter,
        cardholderName: {
          eq: `${searchTerm}`,
        },
      };
    }
    return (API.graphql(graphqlOperation(getCards, { input })) as Promise<GraphQLResult<GetCardsQuery>>).then(
      (result) => {
        return result.data?.getCards as GetCardsPayloadResult;
      }
    );
  }
);

export const getCardByHightnoteId = createAsyncThunk(
  'cards/paymentCards/getCardByHightnoteId',
  async (input: GetCardByHighnoteIdInput) => {
    return (
      API.graphql(
        graphqlOperation(getCardByHighnoteId, {
          input,
        })
      ) as Promise<GraphQLResult<GetCardByHighnoteIdQuery>>
    ).then((result) => {
      return result.data?.getCardByHighnoteId as GetCardsPayload;
    });
  }
);

// Slice for group cards
const groupCardsSlice = createSlice({
  name: 'groupCards',
  initialState,
  reducers: {
    resetGroupCards: (state) => {
      state.groupCards = [];
      state.groupCardsLoading = false;
      state.error = null;
    },
    resetAssinableCards: (state) => {
      state = {
        ...state,
        assinableCards: [],
        assinableCardsLoading: false,
        error: null,
      };
      return state;
    },
    triggerGroupUpdate: (state) => {
      state.updateGroup += 1;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchGroupCards
    builder.addCase(fetchGroupCards.pending, (state) => {
      state.groupCardsLoading = true;
      state.error = null;
    });
    builder.addCase(fetchGroupCards.fulfilled, (state, action: PayloadAction<GetCardsPayloadResult>) => {
      state.groupCardsLoading = false;
      state.groupCards = action.payload?.items || [];
    });
    builder.addCase(fetchGroupCards.rejected, (state, action) => {
      state.groupCardsLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAssinableCards.pending, (state) => {
      state.assinableCardsLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAssinableCards.fulfilled, (state, action: PayloadAction<GetCardsPayloadResult>) => {
      state.assinableCardsLoading = false;
      state.assinableCards = action.payload?.items || [];
    });
    builder.addCase(getCardByHightnoteId.fulfilled, (state, action) => {
      if (action.payload) {
        state.groupCards = state.groupCards.map((card) => {
          if (card.paymentCardId === action.payload.paymentCardId) {
            return action.payload;
          }
          return card;
        });
      }
    });
  },
});

export const groupCardsSelector = (state: RootState): GroupCardsState =>
  state.cards?.groupCards || initialState;

export const { resetGroupCards, resetAssinableCards, triggerGroupUpdate } = groupCardsSlice.actions;

export default groupCardsSlice.reducer;
