import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, TextField } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { HNMerchantCategory, MerchantSpendRule } from 'API';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/store';
import { selectSpendRulesSlice } from 'app/pages/store/spendRulesSlice';

// Define the types for the props
interface SpendingByCategoryProps {
  control: Control<any>;
  selectedSpendRule?: MerchantSpendRule;
  isDisabled?: boolean;
}

const SpendingByCategory: React.FC<SpendingByCategoryProps> = ({
  control,
  selectedSpendRule,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const { rules: highnoteRules } = useAppSelector(selectSpendRulesSlice);
  const [showCategoriesSelect, setShowCategoriesSelect] = useState('0');
  const handleCategoriesSelectChange = (value: string) => {
    setShowCategoriesSelect(value);
  };

  // Ensure selectedSpendRule is defined before accessing its properties
  const merchantCategorySpendRuleId = selectedSpendRule?.merchantCategorySpendRuleId;

  // Retrieve allowed categories safely
  const allowedCategories: HNMerchantCategory[] =
    merchantCategorySpendRuleId &&
    highnoteRules?.get(merchantCategorySpendRuleId) &&
    highnoteRules.get(merchantCategorySpendRuleId)?.allowedCategory
      ? (highnoteRules.get(merchantCategorySpendRuleId)?.allowedCategory as HNMerchantCategory[])
      : [];

  // Retrieve blocked categories safely
  const blockedCategories: HNMerchantCategory[] =
    merchantCategorySpendRuleId &&
    highnoteRules?.get(merchantCategorySpendRuleId) &&
    highnoteRules.get(merchantCategorySpendRuleId)?.blockedCategory
      ? (highnoteRules.get(merchantCategorySpendRuleId)?.blockedCategory as HNMerchantCategory[])
      : [];

  useEffect(() => {
    if (selectedSpendRule) {
      if (allowedCategories.length) {
        handleCategoriesSelectChange('allowed');
      } else if (blockedCategories.length) {
        handleCategoriesSelectChange('blocked');
      } else handleCategoriesSelectChange('0'); // no restrictions
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '16px',
        backgroundColor: (theme) => theme.palette.primary.lighter,
        borderRadius: '16px',
      }}
    >
      <Typography variant="h4" color="primary.main" mb={0.5}>
        {t('spendingByCategory')}
      </Typography>
      <Typography variant="small" mb={1}>
        {t('byMerchantCategoryCodes')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '24px',
          backgroundColor: '#fff',
          borderRadius: '16px',
        }}
      >
        <Controller
          name="spendingByCategoryType"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  handleCategoriesSelectChange(event.target.value);
                }}
                variant="standard"
                disableUnderline
                disabled={!!selectedSpendRule || isDisabled}
                value={showCategoriesSelect}
              >
                <MenuItem value={'0'}>
                  <em>{t('noRestrictions')}</em>
                </MenuItem>
                <MenuItem value={'blocked'}>{t('allCategoriesExcept')}</MenuItem>
                <MenuItem value={'allowed'}>{t('noCategoriesExcept')}</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        {/* Spending by Category options */}
        {showCategoriesSelect !== '0' && (
          <Box mt={1}>
            <Controller
              name="spendingByCategory"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  sx={{ backgroundColor: '#fff' }}
                  multiple
                  onChange={(event, value) => field.onChange(value)}
                  options={Object.values(HNMerchantCategory)}
                  disabled={!!selectedSpendRule || isDisabled}
                  value={
                    allowedCategories.length
                      ? allowedCategories
                      : blockedCategories.length
                      ? blockedCategories
                      : field.value
                  }
                  getOptionLabel={(option: HNMerchantCategory) => option}
                  filterSelectedOptions
                  id="spendingByCategory-autocomplete-input"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SpendingByCategory;
