import { OnAnyPaidolUserByUserIdSubscription, Role } from 'API';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  AuthState,
  getCurrentUser,
  initialState as initialAuthState,
  refreshUserSession,
  selectAuth,
} from 'app/store/authSlice';
import {
  UserCompaniesState,
  getUserCompanies,
  initialState as initialUserCompaniesState,
  selectUserCompanies,
} from 'app/store/userCompaniesSlice';
import { getUserProfile } from 'app/store/userProfileSlice';
import { API, Logger, graphqlOperation } from 'aws-amplify';
import { onAnyPaidolUserByUserId } from 'graphql/subscriptions';
import { ReactNode, createContext, useContext, useEffect, useRef } from 'react';
import { useLogger } from 'util/logging';
import { Observable, ZenObservable } from 'zen-observable-ts';

export interface AuthContextData {
  authState: AuthState;
  userCompaniesState: UserCompaniesState;
}

export const AuthContext = createContext<AuthContextData>({
  authState: initialAuthState,
  userCompaniesState: initialUserCompaniesState,
});

interface AuthProviderProps {
  children: ReactNode;
}

function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const userCompaniesState = useAppSelector(selectUserCompanies);
  const userSub = authState.user?.sub;
  const hasRefreshed = useRef<boolean>(false);

  useEffect(() => {
    dispatch(getCurrentUser());
    const refreshTokenInterval = setInterval(() => {
      // Refresh token periodically
      dispatch(getCurrentUser());
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, [dispatch]);

  useEffect(() => {
    let subscription: ZenObservable.Subscription;

    if (userSub) {
      dispatch(getUserProfile(userSub));
      dispatch(getUserCompanies(userSub));

      subscription = (
        API.graphql(
          graphqlOperation(onAnyPaidolUserByUserId, { user_id: userSub })
        ) as Observable<OnAnyPaidolUserByUserIdSubscription>
      ).subscribe({
        next: (_result) => {
          if (!hasRefreshed.current) {
            hasRefreshed.current = true;
            // PaidolUser records are used for API auth, so refresh
            // the user's access token:
            dispatch(refreshUserSession());

            // Subscription results don't appear to include
            // nested Paidol(?) so just force-reload all the user's
            // UserPaidols instead:
            dispatch(getUserCompanies(userSub));
          }
        },
      });
    }

    return () => {
      hasRefreshed.current = false;
      subscription?.unsubscribe();
    };
  }, [dispatch, userSub]);

  return <AuthContext.Provider value={{ authState, userCompaniesState }}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthState {
  return useContext(AuthContext).authState;
}

export function useUserCompanies(): UserCompaniesState {
  return useContext(AuthContext).userCompaniesState;
}

export function useUserHasRole(neededRole: Role): boolean | undefined {
  const logger = useLogger();
  const { userRole, isUserCompaniesStateUnknown } = useUserCompanies();

  if (isUserCompaniesStateUnknown || !userRole) {
    return undefined;
  }

  return userHasRole(neededRole, userRole, logger);
}

export function userHasRole(neededRole: Role, userRole: Role, logger: Logger) {
  switch (neededRole) {
    case Role.ADMINISTRATOR:
      return userRole === Role.ADMINISTRATOR;
    case Role.GROUP_ADMINISTRATOR:
      return userRole === Role.GROUP_ADMINISTRATOR || userRole === Role.ADMINISTRATOR;
    case Role.MEMBER:
      return userRole !== Role.CARDHOLDER;
    case Role.CARDHOLDER:
      return true;
    default:
      logger.error('Unrecognized user role', neededRole, 'assumed FALSE');
      return false; // This should never happen
  }
}

export default AuthProvider;
